var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      value: _vm.value,
      items: _vm.items,
      "item-text": "text",
      "item-value": "value",
      outlined: "",
      dense: "",
      disabled: "",
    },
    on: { change: _vm.onChange },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function () {
          return [
            _vm.required
              ? _c("span", { staticClass: "red--text" }, [
                  _c("strong", [_vm._v("* ")]),
                ])
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.label) + " "),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }