<template>
  <v-select
    :value="value"
    :items="items"
    item-text="text"
    item-value="value"
    outlined
    dense
    @change="onChange"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:label>
      <span
        v-if="required"
        class="red--text"
      >
        <strong>* </strong>
      </span>
      {{ label }}
    </template>
  </v-select>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: null,
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    items() {
      return [
        {
          text: this.$t('laden'),
          value: 'LADEN',
        },
        {
          text: this.$t('empty'),
          value: 'EMPTY',
        },
      ];
    },
  },
  async created() {
    if (!this.value && this.autoSelectFirst) {
      this.$emit('change', 'LADEN');
    }
  },
  watch: {},
  methods: {
    onChange(value) {
      return this.$emit('change', value);
    },
  },
};
</script>

<style></style>
